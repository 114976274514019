import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

const Slider = ({
  inputName,
  inputType,
  minValue,
  maxValue,
  infoContent,
  value,
  onChange,
}) => {
  const [sliderValue, setSliderValue] = useState(value);
  const [textInputValue, setTextInputValue] = useState(
    value !== 0 ? String(value) : ""
  );
  const [isInfoVisible, setInfoVisibility] = useState(false);

  useEffect(() => {
    setSliderValue(value);
    setTextInputValue(value !== 0 ? String(value) : ""); // Update to empty string if value is 0
  }, [value]);

  const handleSliderChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setSliderValue(newValue);
    onChange(newValue);
  };

  const handleTextInputChange = (event) => {
    const newValue = event.target.value;
    setTextInputValue(newValue);
    onChange(parseInt(newValue, 10) || minValue); // Ensure newValue is parsed as an integer or set to minValue if invalid
  };

  const handleQuestionMarkClick = (event) => {
    setInfoVisibility(!isInfoVisible);
  };

  return (
    <div id="text-input-div">
      <label>
        {isInfoVisible ? <span id="popup-info">{infoContent}</span> : null}
        {inputName}
        <input
          type="text"
          value={textInputValue}
          onChange={handleTextInputChange}
        />
        {inputType}
        <FontAwesomeIcon
          icon={faCircleQuestion}
          id="question-mark"
          onClick={handleQuestionMarkClick}
        />
      </label>
      <input
        type="range"
        value={sliderValue}
        min={minValue}
        max={maxValue}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default Slider;
