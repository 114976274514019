function CalculateResultCathegoryC(
  maternalAge,
  maternalHeight,
  infantBirthWeight,
  previousInfantBirthWeight,
  previousOasi,
  previousVacuumDelivery,
  previousMedioLateralEpisiotomy,
  vacuumDelivery,
  occiputPosterior,
  forcepsDelivery,
  shoulderDystocia
) {
  var maternalAgeSplitted = maternalAge / 5;
  var maternalHeightSplitted = maternalHeight / 10;
  var infantBirthWeightSplitted = infantBirthWeight / 100;
  var previousBirthWeightSplitted = previousInfantBirthWeight / 100;

  var formulaA = Math.pow(Math.max(maternalAgeSplitted - 25 / 5, 0), 3);
  var formulaB = Math.pow(Math.max(maternalAgeSplitted - 36 / 5, 0), 3);
  var formulaC = Math.pow(Math.max(maternalAgeSplitted - 31 / 5, 0), 3);

  console.log(formulaA, formulaB, formulaC);

  var Y =
    -6.7467 +
    0.4222 * maternalAgeSplitted +
    -0.0949 *
      ((formulaA - formulaB) / (11 / 5) - (formulaC - formulaB) / (5 / 5)) +
    4.1428 * previousOasi +
    0.1828 * infantBirthWeightSplitted +
    -0.089 * previousBirthWeightSplitted +
    0.9782 * vacuumDelivery +
    0.6416 * previousMedioLateralEpisiotomy +
    -0.2661 * maternalHeightSplitted +
    0.5981 * occiputPosterior +
    0.2256 * previousVacuumDelivery +
    0.6154 * shoulderDystocia +
    1.6163 * forcepsDelivery +
    -0.0507 * previousOasi * infantBirthWeightSplitted +
    -0.5943 * previousOasi * previousMedioLateralEpisiotomy;

  console.log(Y);
  let result = Math.exp(Y) / (1 + Math.exp(Y));
  var resultInPercent;
  if (result * 100 < 1 && result * 100 > 0.5) {
    result = result * 100;
    resultInPercent = parseFloat(result.toFixed(1));
  } else if(result * 100 > 15){
    resultInPercent = '>15';
  }
  else{
    resultInPercent = Math.round(result * 100);
  }
  return resultInPercent;
}
export default CalculateResultCathegoryC;
